import axiosInstance from "../http-client";

export const fetchBlobDetails = async (maxRetries = 3) => {
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const response = await axiosInstance.get("Config/blobConfig");
      if (response?.data) {
        localStorage.setItem(
          "ContainerSasToken",
          response.data.containerSasToken
        );
        return;
      }
    } catch (error) {
      retries++;
      if (retries === maxRetries) {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  }
};
