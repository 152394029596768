import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ComponentType } from "react";
import { getCookie } from "../../helper/cookieFinder.ts";

const msccCookieValue = getCookie("MSCC");
const c1Value = msccCookieValue?.trim().split("-")[1]?.trim().split("=")[1];

let appInsights;
const reactPlugin = new ReactPlugin();
if (c1Value !== "1") {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      correlationHeaderExcludedDomains: ["uhf.microsoft.com"],
    },
  });
  appInsights.loadAppInsights();
}

const withAITrackingHOC = (Component: ComponentType<unknown>) =>
  withAITracking(reactPlugin, Component);

export default withAITrackingHOC;
export { reactPlugin, appInsights };
