import { useEffect } from "react";
import { fetchHeaderFooter } from "../../services/ConfigurationServices/UHFServices.ts";

const MsFooter = () => {
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchHeaderFooter();
      if (typeof data === "string") {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, "text/xml");
        parseXmlData(doc);
      } else {
        console.error("Invalid data type. Expected a string.");
      }
    };
    fetchData();
  }, []);

  function parseXmlData(xmlData) {
    const tempHtml =
      xmlData.getElementsByTagName("footerHtml")[0].childNodes[0];
    const tempCss =
      xmlData.getElementsByTagName("cssIncludes")[0].childNodes[0];
    const tempJs =
      xmlData.getElementsByTagName("javascriptIncludes")[0].childNodes[0];

    const cssLink = document.getElementById("css-link");
    if (cssLink) cssLink.innerHTML = tempCss.wholeText;

    const footer = document.getElementById("footer");
    if (footer) footer.innerHTML = tempHtml.wholeText;
    const csFooterElement = document.getElementById("uhf-footer");
    if (csFooterElement) {
      csFooterElement.style.marginTop = "0";
    }

    const srcRegex = /<script src="([^"]*)"><\/script>/;
    const match = tempJs.wholeText.match(srcRegex);
    if (match) {
      const script = document.createElement("script");
      script.src = match[1];
      script.type = "text/javascript";
      const jss = document.getElementById("footer-container");
      if (jss) {
        jss.appendChild(script);
      }
    }
  }

  return (
    <div id="footer-container" className="footer ms-footer">
      <div id="css-link"></div>
      <div id="footer" className="uinhf-footer-ma"></div>
    </div>
  );
};

export default MsFooter;
