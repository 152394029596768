export const fetchHeaderFooter = async (
  maxRetries = 3
): Promise<string | undefined> => {
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const response = await fetch(
        "https://uhf.microsoft.com/en-us/shell/xml/MSInStoreSetup?headerid=MSInStoreSetupHeader&footerid=MSInStoreSetupFooter"
      );
      const data = await response.text();
      return data;
    } catch (error) {
      retries++;
      if (retries === maxRetries) {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  }
};
