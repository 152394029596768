export const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [cookieName] = cookie.trim().split("=");
    if (cookieName === name) {
      return cookie;
    }
  }
  return null;
};
