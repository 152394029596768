import * as React from "react";
import { Field, ProgressBar } from "@fluentui/react-components";

export const SquareProgressBar = () => {
  return (
    <Field validationState="none">
      <ProgressBar />
    </Field>
  );
};
