import { lazy, Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import MsHeader from "./components/MS Header/Msheader.tsx";
import MsFooter from "./components/MS Footer/footer.component.jsx";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { useEffect } from "react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./services/ConfigurationServices/ApplicationInsightsService.ts";
import { fetchBlobDetails } from "../src/services/ConfigurationServices/SasTokenService.ts";
import { SquareProgressBar } from "./components/Common/SquareProgressBar.tsx";

const StoreSelection = lazy(() =>
  import("./components/StoreSelectionDashboard/StoreSelection.tsx")
);
const ResourceDashboard = lazy(() =>
  import("./components/ResourcesDashboard/ResourcesDashboard.tsx")
);
const UnAuthorized = lazy(() => import("./components/Common/UnAuthorized.tsx"));

function App() {
  useEffect(() => {
    fetchBlobDetails();
  }, []);
  return (
    <div className="App">
      <MsHeader />
      <Router>
        <AppInsightsContext.Provider value={reactPlugin}>
          <FluentProvider theme={webLightTheme}>
            <Suspense fallback={<SquareProgressBar />}>
              <Routes>
                <Route path="/" element={<StoreSelection />}>
                  <Route path=":route" element={<StoreSelection />} />
                  <Route path=":route/:store?" element={<StoreSelection />} />
                </Route>
                <Route path="/resources" element={<ResourceDashboard />} />
                <Route path="*" element={<Navigate to="/unauthorized" />} />
                <Route path="/unauthorized" element={<UnAuthorized />} />
              </Routes>
            </Suspense>
          </FluentProvider>
        </AppInsightsContext.Provider>
      </Router>
      <MsFooter isFieldUser={false} />
    </div>
  );
}

export default App;
