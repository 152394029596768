import React, { useEffect } from "react";
import { fetchHeaderFooter } from "../../services/ConfigurationServices/UHFServices.ts";

const MsHeader = (props: any) => {
  useEffect(() => {
    const fetchData = async () => {
      const data: string | undefined = await fetchHeaderFooter();
      if (typeof data === "string") {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, "text/xml");
        parseXmlData(doc);
      } else {
        console.error("Invalid data type. Expected a string.");
      }
    };
    fetchData();
  }, []);

  function parseXmlData(xmlData: any) {
    const tempHtml =
      xmlData.getElementsByTagName("headerHtml")[0].childNodes[0];
    const tempCss =
      xmlData.getElementsByTagName("cssIncludes")[0].childNodes[0];

    const tempJs =
      xmlData.getElementsByTagName("javascriptIncludes")[0].childNodes[0];

    const cssLink = document.getElementById("css-link");
    if (cssLink) cssLink.innerHTML = tempCss.wholeText;

    const header = document.getElementById("header");
    if (header) header.innerHTML = tempHtml.wholeText;

    const srcRegex = /<script src="([^"]*)"><\/script>/;
    const match = tempJs.wholeText.match(srcRegex);
    if (match) {
      const script = document.createElement("script");
      script.src = match[1];
      script.type = "text/javascript";
      const jss = document.getElementById("header-container");
      if (jss) {
        jss.appendChild(script);
      }
    }
  }

  return (
    <div id="header-container" className="header">
      <div id="css-link"></div>
      <div id="header"></div>
    </div>
  );
};

export default MsHeader;
